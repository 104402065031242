import React from "react";
import LazyLoad from "react-lazyload";
import Link from "next/link";
import { Box, Divider, Heading, Image, List, ListItem } from "@chakra-ui/react";
import { MDXProvider } from "@mdx-js/react";
import BackgroundImageBox from "@src/components/BackgroundImageBox";
import Text from "@src/components/Text";
import Header from "@src/components/Header";
import Footer from "@src/components/Footer";
import SeoMeta from "@src/components/SeoMeta";
import Breadcrumbs from "@src/components/Breadcrumbs";
import TableOfContents from "@src/components/TableOfContents";
import AuthorBox from "@src/components/AuthorBox";
import { OptimizedImage } from "@src/@types/image";
import { Author } from "@src/constants/authors";
import { useRouter } from "next/router";
import { generatePostId } from "@src/lib/post";
import { useRelatedPostsQuery } from "@src/generated/graphql";
import RelatedPosts from "@src/components/RelatedPosts";
import ShareButton from "@src/components/ShareButton";
import TagList from "@src/components/TagList";
import { SITE_ROOT_URL } from "@src/constants/website";

/* eslint-disable react/display-name */
const components = {
  h2: (props) => (
    <Heading
      as="h2"
      fontSize="1.4rem"
      my={5}
      pb={2}
      borderBottom="3px solid"
      style={{ borderImage: "linear-gradient(90deg,#D53F8C,#EF3D59) 1" }}
      {...props}
    />
  ),
  h3: (props) => (
    <Heading
      as="h3"
      my={3}
      pl={2}
      borderStyle="solid"
      borderLeftWidth={2}
      fontSize="1.2rem"
      borderColor="pink.500"
      {...props}
    />
  ),
  h4: (props) => <Heading as="h4" fontSize="1.1rem" mt={2} {...props} />,
  ul: (props) => <List styleTyle="disc" {...props} />,
  ol: (props) => <List as="ol" styleTyle="decimal" {...props} />,
  li: (props) => <ListItem {...props} />,
  hr: (props) => <Divider my={8} {...props} />,
  img: (props) => (
    <LazyLoad>
      <Image borderRadius={5} mx="auto" my={5} {...props} />
    </LazyLoad>
  ),
  p: (props) => <Text my={4} {...props} />,
  strong: (props) => <Text as="strong" color="pink.500" {...props} />,
  a: (props) => <Link {...props} color="cyan.500" />,
};

/* eslint-disable react/display-name */

interface Props {
  meta: {
    title: string;
    description: string;
    topImg: OptimizedImage;
    author?: Author;
    tags?: string[];
  };
  children: JSX.Element[];
}

const withTOC = (children: JSX.Element[]) => {
  const headings: string[] = children
    .filter((child) => child.props && child.props.originalType === "h2")
    .map((h2) => h2.props.children);
  let index = 0;
  children = React.Children.map(children, (child) => {
    if (child.props && child.props.originalType === "h2") {
      const id = index.toString();
      index++;
      return React.cloneElement(child, { id }, child.props.children);
    }
    return React.cloneElement(child);
  });
  const firstH2 = children.findIndex((tag) => tag.props.originalType === "h2");
  return [
    ...children.slice(0, firstH2),
    <Box key="toc" my={10}>
      <TableOfContents headings={headings} />
    </Box>,
    ...children.slice(firstH2),
  ];
};

export default function PostLayout({ meta, children }: Props) {
  const router = useRouter();
  const id = generatePostId(router.pathname);
  const { data, loading, error } = useRelatedPostsQuery({ variables: { id } });
  if (error) {
    console.log(error);
  }

  return (
    <>
      <SeoMeta
        id={id}
        title={meta.title}
        description={meta.description}
        image={`${SITE_ROOT_URL}${meta.topImg.src}`}
        tags={meta.tags || []}
      />
      <Header />
      <Box
        as="main"
        role="main"
        className="main"
        maxW={1000}
        mx={[2, "auto"]}
        my={[5, "30px"]}
      >
        <Box mb={2}>
          <Breadcrumbs title={meta.title} />
        </Box>
        <Box
          as="article"
          p={[3, 6]}
          boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 12px, rgba(0, 0, 0, 0.04) 0px 6px 6px"
          style={{ wordWrap: "break-word" }}
        >
          <BackgroundImageBox
            bgImg={meta.topImg}
            bgImgPlaceholder="lqip"
            alt={meta.title}
            imgStyle={{ height: "100%", objectFit: "cover" }}
            style={{ textAlign: "center" }}
          >
            <Heading
              as="h1"
              display="inline-block"
              m="auto"
              p={[2, 6]}
              textAlign="left"
              fontSize="2rem"
              color="white"
            >
              {meta.title}
            </Heading>
          </BackgroundImageBox>
          <Box my={2}>
            {meta.tags && (
              <>
                <Box>
                  <Heading
                    as="h3"
                    display="inline-block"
                    m={1}
                    py={1}
                    borderBottom="2px solid"
                    borderColor="gray.500"
                    fontSize="md"
                  >
                    タグ一覧
                  </Heading>
                </Box>
                <TagList tags={meta.tags} />
              </>
            )}
          </Box>
          <MDXProvider components={components}>{withTOC(children)}</MDXProvider>
          <Divider />
          {meta.author && <AuthorBox author={meta.author} />}
        </Box>
        {data && data.relatedPosts.length > 0 && (
          <Box mt={12}>
            <RelatedPosts
              posts={data ? data.relatedPosts : []}
              loading={loading}
            />
          </Box>
        )}
      </Box>
      <Box position="fixed" left={5} bottom={5}>
        <ShareButton />
      </Box>
      <Footer />
    </>
  );
}
