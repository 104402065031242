import React from "react";
import { Avatar, Box, Flex } from "@chakra-ui/react";
import LazyLoad from "react-lazyload";
import Text from "@src/components/Text";
import { Author } from "@src/constants/authors";

interface Props {
  author: Author;
}

export default function AuthorBox({ author }: Props) {
  return (
    <Flex alignItems="center">
      <Box ml="3" textAlign="center">
        <LazyLoad>
          <Avatar size="lg" src={author.img.src} />
        </LazyLoad>
        <Text fontSize="sm" fontWeight="bold">
          {author.name}
        </Text>
      </Box>
      <Box ml="3">
        <Text fontSize="xs" fontWeight="bold">
          Author
        </Text>
        <Text fontSize="sm">{author.profile}</Text>
      </Box>
    </Flex>
  );
}
