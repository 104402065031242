import React from "react";
import Link from "next/link";
import { Flex, Box, Text } from "@chakra-ui/react";
import LazyImage from "./LazyImage";
import { PostMeta } from "@src/constants/post";
import { truncate } from "@src/lib/string";

interface Props {
  meta: PostMeta;
  text?: string;
}

export default function LinkWithThumbnail({ meta, text }: Props) {
  return (
    <Link href={meta.path}>
      <a>
        <Flex
          borderRadius={5}
          boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 12px, rgba(0, 0, 0, 0.04) 0px 6px 6px"
          h={[100, 150]}
        >
          <Box w="30%" h="100%" overflow="hidden">
            <LazyImage
              img={meta.topImg}
              alt={meta.title}
              style={{
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
          </Box>
          <Box mx="3" w="70%" alignSelf="center">
            <Text mb={[0, 2]} fontSize={["sm", "md"]} fontWeight="bold">
              {text || meta.title}
            </Text>
            <Text display={["none", "block"]} fontSize="sm">
              {truncate(meta.description, 100)}
            </Text>
          </Box>
        </Flex>
      </a>
    </Link>
  );
}
