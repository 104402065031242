import React from "react";
import { Box } from "@chakra-ui/react";
import Link from "next/link";
import { routes } from "@src/constants/appRoutes";

interface Props {
  tags: string[];
}

export default function TagList({ tags }: Props) {
  const tagList = tags.map((tag) => (
    <Box
      key={tag}
      display="inline-block"
      mx={1}
      my={1}
      p={1}
      boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 12px, rgba(0, 0, 0, 0.04) 0px 6px 6px"
      fontWeight="bold"
      color="pink.500"
    >
      <Link href={routes.postsWithTag(tag)}>
        <a>{tag}</a>
      </Link>
    </Box>
  ));
  return <>{tagList}</>;
}
