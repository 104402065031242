import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { FiShare } from "react-icons/fi";
import { SITE_ROOT_URL } from "@src/constants/website";

interface Props {
  size?: number;
}

export default function ShareButton({ size }: Props) {
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [title, setTitle] = useState("");
  const [topImgUrl, setTopImgUrl] = useState("");
  const absoluteUrl = SITE_ROOT_URL + router.pathname;
  useEffect(() => {
    setTitle(document.getElementsByTagName("title").item(0).innerText);
    const metaTags = document.getElementsByTagName("meta");
    for (let i = 0; i < metaTags.length; i++) {
      const item = metaTags.item(i);
      if (item.getAttribute("property") == "og:image") {
        setTopImgUrl(item.getAttribute("content"));
      }
    }
  }, []);
  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme="pink"
        h={size || 12}
        w={size || 12}
        borderRadius={10}
      >
        <FiShare size={size || 30} />
      </Button>
      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>この記事をシェアする</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="space-around">
              <Box>
                <LineShareButton url={absoluteUrl} title={title}>
                  <LineIcon size={50} style={{ borderRadius: 5 }} />
                </LineShareButton>
              </Box>
              <Box>
                <TwitterShareButton url={absoluteUrl} title={title}>
                  <TwitterIcon size={50} style={{ borderRadius: 5 }} />
                </TwitterShareButton>
              </Box>
              <Box>
                <FacebookShareButton url={absoluteUrl} title={title}>
                  <FacebookIcon size={50} style={{ borderRadius: 5 }} />
                </FacebookShareButton>
              </Box>
              <Box>
                <PinterestShareButton
                  url={absoluteUrl}
                  media={topImgUrl}
                  description={title}
                >
                  <PinterestIcon size={50} style={{ borderRadius: 5 }} />
                </PinterestShareButton>
              </Box>
              <Box>
                <EmailShareButton url={absoluteUrl} title={title}>
                  <EmailIcon size={50} style={{ borderRadius: 5 }} />
                </EmailShareButton>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>閉じる</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
