import React, { CSSProperties } from "react";
import Link from "next/link";
import { Box, List, ListItem, ListIcon, Text } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

interface Props {
  headings: string[];
  style?: CSSProperties;
}

export default function TableOfContents({ headings, style }: Props) {
  const listItems = headings.map((heading, i) => (
    <ListItem key={heading} my={2}>
      <Link href={`#${i}`}>
        <a>
          <ListIcon color="teal" icon={<ChevronRightIcon />} />
          <Text as="span">{heading}</Text>
        </a>
      </Link>
    </ListItem>
  ));
  return (
    <Box
      py={3}
      borderTop="solid 3px"
      borderBottom="solid 3px"
      borderColor="teal.500"
      style={style}
    >
      <Text fontWeight="bold" ml={2}>
        - 目次
      </Text>
      <List>{listItems}</List>
    </Box>
  );
}
