import React from "react";
import { Box, Heading, Stack } from "@chakra-ui/react";
import { RelatedPostsQuery } from "@src/generated/graphql";
import LinkWithThumbnail from "@src/components/LinkWithThumbnail";
import Loading from "@src/components/Loading";

interface Props {
  posts: RelatedPostsQuery["relatedPosts"];
  loading: boolean;
}

export default function RelatedPosts({ posts, loading }: Props) {
  if (loading) {
    return <Loading />;
  }

  if (posts.length === 0) {
    return <></>;
  }

  const postLinks = posts.map((p) => (
    <Box key={p.id}>
      <LinkWithThumbnail
        meta={{
          path: new URL(p.url).pathname,
          title: p.title,
          description: p.description,
          topImg: { src: p.screenImgUrl, preSrc: p.screenImgUrl },
        }}
      />
    </Box>
  ));
  return (
    <>
      <Heading as="h4" fontSize="xl" my={2}>
        関連記事
      </Heading>
      <Stack spacing={2}>{postLinks}</Stack>
    </>
  );
}
